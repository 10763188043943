@import '../../../styles/base/_bem.scss';
@import '../../../styles/base/_colors.scss';
@import '../../../styles/base/_types.scss';
@import '../../../styles/base/_grid.scss';
@import '../../../styles/base/_clearfix.scss';

@include b('header') {
  @include clearfix();

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  z-index: 100;
  color: $color-header-text;
  background-color: $color-header-bg;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;

  img.logo {
    width: 135px;
  }

  @include e('container') {
    @include make-container();
    @include make-container-max-widths();

    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @include e('logo') {
    margin-right: 50px;
  }

  @include e('header-logo') {
    color: #fff;
  }

  @include e('user-info') {
    position: absolute;
    right: 0;
    display: flex;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  @include e('user-signout') {
    padding: 0 10px;

    a,
    a:hover,
    a:focus,
    a:active,
    a:visited {
      color: #fff;
    }
  }
}  
