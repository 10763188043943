@import '../../styles/base/_bem.scss';
@import '../../styles/base/_colors.scss';
@import '../../styles/base/_types.scss';
@import '../../styles/base/_grid.scss';
@import '../../styles/base/_clearfix.scss';

@include b('empty-layout') {
  @include clearfix();

  min-height: 100%;

  @include e('container') {
    @include make-container();
    @include make-container-max-widths();

    padding-top: 20px;
  }
}  
