@import '../../styles/base/_bem.scss';
@import '../../styles/base/_colors.scss';
@import '../../styles/base/_types.scss';
@import '../../styles/base/_grid.scss';
@import '../../styles/base/_clearfix.scss';

@include b('page-profile') {
  @include clearfix();

  @include e('form-container') {
    @include makeFormContainer();
  }

  @include e('plan-list') {
    ul > li {
      margin-bottom: 15px;
    }
  }
}  
