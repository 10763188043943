@import '../../../styles/base/_bem.scss';
@import '../../../styles/base/_colors.scss';
@import '../../../styles/base/_types.scss';
@import '../../../styles/base/_clearfix.scss';

@include b('tab-fields') {
  @include clearfix();

  @include e('form-container') {
    @include makeFormContainer();
    margin-bottom: 0;    
  }

  @include e('row-container') {
    display: table;
    width: 100%;
    margin-bottom: 20px;

    @include m('header') {
      font-weight: bold;
    }
  }
}
