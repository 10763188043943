@import '../../../styles/base/_bem.scss';
@import '../../../styles/base/_colors.scss';
@import '../../../styles/base/_types.scss';

@include b('sf') {
  margin: 10px 0;

  @include e('submit') {
    margin-top: 20px;
  }

  @include e('extra-buttons') {
    display: inline-block;
    vertical-align: middle;
    line-height: 27.98px;
  }

  @include e('default') {
    position: relative;
    top: -5px;
    opacity: 0;
  }

  @include e('enter') {
    position: relative;
    top: 0;
    opacity: 1;
    transition: all ease-in-out 0.2s;
  }

  @include e('leave') {
    position: relative;
    top: -10px;
    opacity: 0;
    transition: all ease-in-out 0.2s;
  }

  @include e('active') {
    position: relative;
    top: 0;
    opacity: 1;
  }
}
