@import '../../../styles/base/_bem.scss';
@import '../../../styles/base/_colors.scss';
@import '../../../styles/base/_types.scss';
@import '../../../styles/base/_grid.scss';
@import '../../../styles/base/_clearfix.scss';

@include b('logo') {
  display: inline-block;

  a,
  a:hover,
  a:active,
  a:focus,
  a:visited {
    text-decoration: none;
    font-size: 1.25rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-weight: 400;
    color: #fafafa;
    padding: 7px 0 10px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.24);
  }

  @include m('dark') {
    a,
    a:hover,
    a:active,
    a:focus,
    a:visited {
      color: #2b645b;
    }
  }
}  
