@import '../../styles/base/_bem.scss';
@import '../../styles/base/_colors.scss';
@import '../../styles/base/_types.scss';
@import '../../styles/base/_grid.scss';
@import '../../styles/base/_clearfix.scss';

@include b('app-layout') {
  @include clearfix();

  @include e('container') {
    @include make-container();
    @include make-container-max-widths();

    padding: 70px 0 50px 0;

    @include media-breakpoint-down(sm) {
      padding: 70px 10px 50px 10px;

      h1 {
        text-align: center;
      }
    }
  }
}  
