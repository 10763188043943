﻿$color-header-text: #fafafa;
$color-header-text-active: #fff;
$color-header-bg: #36534b;
$color-header-bg-active: #4a7166;

$color-item-border: #ccc;
$color-text-muted: #888;
$color-white: #fff;

$color-page-text: rgba(38,66,85,.8);

/* Buttons colors */

$color-btn-text: #133631;
$color-btn-text-hover: #133631;
$color-btn-text-disabled: #999;
$color-btn-bg: #fff;
$color-btn-bg-hover: rgba(200, 200, 200, 0.1);
$color-btn-bg-disabled: rgba(100, 100, 100, 0.1);
$color-btn-border: #ccc;
$color-btn-border-hover: #ccc;
$color-btn-border-disabled: #ccc;

$color-btn-primary-text: #133631;
$color-btn-primary-text-hover: #133631;
$color-btn-primary-text-disabled: #133631;
$color-btn-primary-bg: #e27d5f;
$color-btn-primary-bg-hover: #dd6642;
$color-btn-primary-bg-disabled: #e7947c;
$color-btn-primary-border: #df7151;
$color-btn-primary-border-hover: #da5a34;
$color-btn-primary-border-disabled: #e5896d;

$color-btn-secondary-text: #36534b;
$color-btn-secondary-text-hover: #3c5d54;
$color-btn-secondary-text-disabled: #4a7166;
$color-btn-secondary-bg: transparent;
$color-btn-secondary-bg-hover: transparent;
$color-btn-secondary-bg-disabled: transparent;
$color-btn-secondary-border: #36534b;
$color-btn-secondary-border-hover: #3c5d54;
$color-btn-secondary-border-disabled: #4a7166;

$color-btn-link-text: #2c689c;
$color-btn-link-text-hover: #285f8f;
$color-btn-link-text-disabled: #337ab7;
$color-btn-link-bg: transparent;
$color-btn-link-bg-hover: rgba(200, 200, 200, 0.1);
$color-btn-link-bg-disabled: transparent;
$color-btn-link-border: transparent;
$color-btn-link-border-hover: transparent;
$color-btn-link-border-disabled: transparent;

$color-btn-action-checked-bg: #f36600;
$color-btn-action-checked-bg-hover: #e25f00;
$color-btn-action-checked-bg-disabled: #ff6e05;

$color-primary: #fff;
$color-secondary: #133631;

$color-border-primary: #e25f00;
$color-border-secondary: #777;

$color-bg-primary: #f36600;
$color-bg-secondary: transparent;

$color-bg-hover-primary: #d15800;
$color-bg-hover-secondary: rgba(200, 200, 200, 0.1);

$color-bg-disabled-primary: #ff8c38;
$color-bg-disabled-secondary: transparent;

$color-rsvp-going: green;
$color-rsvp-not-going: red;
$color-rsvp-maybe-going: orange;
$color-rsvp-not-decided: gray;
$color-rsvp-unknown: #333;

$color-icon: #d3e1eb;

$color-item-border-color: #ccc;

$color-label-bg: #e25f00;
$color-label-text: #fff;

$color-error-bg: rgba(255, 0, 0, 0.10);
$color-error-border: rgba(255, 0, 0, 0.11);
$color-error-text: rgb(121, 2, 2);

$color-info-bg: #d5e5e1;
$color-info-border: #d5e5e1;
$color-info-text: #36534b;

$color-success-bg: rgb(124, 173, 123);
$color-success-border: rgb(106, 148, 111);
$color-success-text: rgb(10, 147, 25);

$tab-bg: #d5e5e1;
$tab-text: #36534b;
$tab-bg-active: #36534b;
$tab-text-active: #e0f2ef;
$tab-bg-hover: #36534b98;
$tab-text-hover: #e0f2ef;
