@import '../../styles/base/_bem.scss';
@import '../../styles/base/_colors.scss';
@import '../../styles/base/_types.scss';
@import '../../styles/base/_grid.scss';
@import '../../styles/base/_clearfix.scss';

@include b('forms-page') {
  @include clearfix();

  @include e('list') {
    display: table;
    width: 100%;
    margin-bottom: 5px;
  }

  @include e('row') {
    display: table-row;

    &:hover {
      background-color: #d9efef;
    }
  }

  @include e('col') {
    display: table-cell;
    vertical-align: middle;
    padding: 5px 5px 0 5px;
    width: auto;

    @include media-breakpoint-down(sm) {
      vertical-align: top;
    }

    @include m('icon') {
      width: 64px;

      span::before {
        font-size: 56px;
        color: #8fc0ba;
      }

      @include media-breakpoint-down(sm) {
        width: 48px;
        
        span::before {
          font-size: 40px;
        }
      }
    }

    @include m('submissions-cnt') {
      text-align: right;
      width: 80px;
      min-width: 80px;

      @include media-breakpoint-down(sm) {
        text-align: left;
        display: none;
      }
    }

    @include m('actions') {
      text-align: right;
      width: 300px;
      min-width: 300px;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  @include e('actions') {
    display: none;

    @include media-breakpoint-down(sm) {
      display: block;
      margin-top: 5px;
    }
  }

  @include e('name') {
    font-weight: bold;
    margin-bottom: 3px;

    @include media-breakpoint-down(sm) {
      display: block;
      width: 100%;
    }
  }
}  


