@import '../../../styles/base/_bem.scss';
@import '../../../styles/base/_colors.scss';
@import '../../../styles/base/_types.scss';
@import '../../../styles/base/_grid.scss';
@import '../../../styles/base/_clearfix.scss';
@import '../../../styles/base/_breakpoints.scss';

@include b('tab-submissions') {
  @include clearfix();

  @include e('date') {
    font-size: 0.9em;
  }

  @include e('row') {
    display: table-row;
  }

  @include e('col') {
    display: table-cell;
    vertical-align: top;
    padding: 0 0 10px 0;
    width: auto;

    @include m('label') {
      width: 150px;
      min-width: 150px;
      font-weight: bold;
    }

    @include m('value') {
      width: auto;
      min-width: 250px;
    }
  }
}  
