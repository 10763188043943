@import '../../styles/base/_bem.scss';
@import '../../styles/base/_colors.scss';
@import '../../styles/base/_types.scss';
@import '../../styles/base/_grid.scss';
@import '../../styles/base/_clearfix.scss';
@import '../../styles/base/_breakpoints.scss';

@include b('page-submission') {
  @include clearfix();

  @include e('block') {
    @include makeFormContainer();
  }

  @include e('detail') {
    display: flex;
    flex-direction: row;

    @include media-breakpoint-down(sm) {
      display: block;
      margin-bottom: 10px;
    }
  }

  @include e('col') {
    width: 100%;
    display: flex;
    flex-direction: column;
    vertical-align: top;
    padding: 10px 0;

    @include media-breakpoint-down(sm) {
      display: block;
      padding: 5px 0;
    }
    
    @include m('name') {
      font-weight: bold;
      width: 150px;
      min-width: 150px;
    }

    @include m('value') {
      padding-left: 20px;
      width: auto;

      @include media-breakpoint-down(sm) {
        padding-left: 0;
      }
    }
  }

  @include e('block') {
    margin-bottom: 30px;
  }
}  
