// ----
// Sass (v3.4.0)
// demo: https://www.sassmeister.com/gist/6dac17c87fcf2d1555c4
// ----

/**
 *
 * Sass (v3.4.0)
 * demo: https://www.sassmeister.com/gist/6dac17c87fcf2d1555c4
 *
 * Example:
 *
 * @include b(drawer) {
 *   background: red;
 *
 *   @include e(content) {
 *     font-size: 12px;
 *
 *     @include m(big) {
 *       font-size: 18px;
 *     }
 *   }
 *
 *   @include m(open) {
 *     color: blue;
 *
 *     @include e(content) {
 *       color: green;
 *     }
 *   }
 * }
 *
 * Result:
 *
 * .drawer {
 *   background: red;
 * }
 *
 * .drawer__content {
 *   font-size: 12px;
 * }
 *
 * .drawer__content--big {
 *   font-size: 18px;
 * }
 *
 * .drawer--open {
 *   color: blue;
 * }
 *
 * .drawer--open .drawer__content {
 *   color: green;
 * }
 *
 */

$elementSeparator: '__';
$modifierSeparator: '--';

@function contains-modifier($selector) {
  $selector: selector-to-string($selector);

  @if str-index($selector, $modifierSeparator) {
    @return true;
  }

  @else {
    @return false;
  }
}

@function selector-to-string($selector) {
  $selector: inspect($selector); //cast to string
  $selector: str-slice($selector, 2, -2); //remove brackets

  @return $selector;
}

@function get-block($selector) {
  $selector: selector-to-string($selector);
  $modifierStart: str-index($selector, $modifierSeparator) - 1;

  @return str-slice($selector, 0, $modifierStart);
}

@mixin b($block) {
  .#{$block} {
    @content;
  }
}

@mixin e($element) {
  $selector: &;

  @if contains-modifier($selector) {
    $block: get-block($selector);

    @at-root {
      #{$selector} {
        #{$block + $elementSeparator + $element} {
          @content;
        }
      }
    }
  }

  @else {
    @at-root {
      #{$selector + $elementSeparator + $element} {
        @content;
      }
    }
  }
}

@mixin m($modifier) {
  @at-root {
    #{&}#{$modifierSeparator + $modifier} {
      @content;
    }
  }
}
