@import '../../styles/base/_bem.scss';
@import '../../styles/base/_types.scss';
@import '../../styles/base/_grid.scss';
@import '../../styles/base/_clearfix.scss';

@include b('page-signin') {
  @include clearfix();

  @include e('container') {
    @include makePageFormContainer();
  }

  @include e('logo-container') {
    text-align: center;
    margin-bottom: 30px;
  }

  @include e('no-account') {
    margin-top: 30px;
    text-align: center;
  }
}
