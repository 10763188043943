@import '../../../styles/base/_bem.scss';
@import '../../../styles/base/_colors.scss';
@import '../../../styles/base/_types.scss';

@include b('fi') {
  margin-bottom: 7px;

  @include e('hint') {
    font-size: 13px;
    color: #34544c;
    padding-left: 3px;
  }

  @include e('error') {
    color: $color-error-text;
  }

  @include e('cbk-input') {
    padding: 10px 0 5px;

    label {
      display: flex;
      flex-direction: row;
      font-weight: normal;
      cursor: pointer;
    }

    input[type="checkbox"] {
      width: 16px;
      margin: 4px 5px 0 0;
      box-shadow: none;
    }
  }
}