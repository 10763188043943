
@mixin applyInputStdProps() {
  box-sizing: border-box;
  border: solid 1px #ccc;
  border-radius: 4px;
  padding: 9px 10px;
  background-color: #f1faf9 !important;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: inset 0px 1px 10px #ccc;
}

form {
  border: none;
  display: block;
}

fieldset {
  border: none;
  display: block;
  padding: 0;
}

label {
  display: block;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;
  color: #133631;
}

label.required::after {
  content: '*';
  font-weight: bold;
  color: red;
  padding-left: 5px;
}

input[type="checkbox"], 
input[type="radio"] {
  cursor: pointer;
  color: #133631;
}

input {
  @include applyInputStdProps();
  width: 100%;
}

textarea {
  @include applyInputStdProps();
  width: 100%;
}

select {
  @include applyInputStdProps();
  width: 100%;
}

input:-internal-autofill-previewed,
input:-internal-autofill-selected,
textarea:-internal-autofill-previewed,
textarea:-internal-autofill-selected,
select:-internal-autofill-previewed,
select:-internal-autofill-selected {
  background-color: #d9f0ef !important;
}
