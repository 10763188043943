@import '../../../styles/base/_bem.scss';
@import '../../../styles/base/_colors.scss';
@import '../../../styles/base/_types.scss';
@import '../../../styles/base/_grid.scss';
@import '../../../styles/base/_clearfix.scss';

@include b('tab-control') {
  @include e('header-container') {
    margin-top: 10px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    border-bottom: solid 2px $tab-bg-active;
  }

  @include e('header') {
    white-space: nowrap;
    list-style-type: none;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;

    @include media-breakpoint-down(sm) {
      width: auto;
    }
  }

  @include e('tab-item') {
    display: inline-block;
    vertical-align: top;
    margin-right: 3px; 
    padding-bottom: 0.5px;
    
    a,
    a:hover,
    a:focus,
    a:active {
      display: inline-block;
      padding: 10px 20px;
      text-decoration: none;
      background-color: $tab-bg;
      color: $tab-text;
      transition: all ease-in-out 0.1s;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    a:hover {
      background-color: $tab-bg-hover;
      color: $tab-text-hover;
    }

    @include m('active') {
      a,
      a:hover,
      a:focus,
      a:active {
        background-color: $tab-bg-active;
        color: $tab-text-active;
        box-shadow: 0 2px 5px #333;
      } 
    }
  }

  @include e('panels') {
    border-bottom: solid 2px $tab-bg-active;
    margin-bottom: 30px;
    position: relative;

    &::after {
      content: ' ';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      display: block;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.75);
    }
  }

  @include e('default') {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }

  @include e('enter') {
    height: auto;
    opacity: 1;
    transition: all ease-in-out 0.3s;
  }

  @include e('leave') {
    height: 0;
    opacity: 0;
    transition: all ease-in-out 0.1s;
  }

  @include e('active') {
    opacity: 1;
    height: auto;
  }
}