@import '../../../styles/base/_bem.scss';
@import '../../../styles/base/_colors.scss';
@import '../../../styles/base/_types.scss';

@mixin makeBlock($t, $bg, $b) {
  padding: 15px 10px;
  border-radius: 4px;
  color: $t;
  background-color: $bg;
  border: solid 1px $b;
  box-shadow: 0 1px 4px #ccc;
}

@mixin makeInlineBlock($t, $bg) {
  color: $t;
  background-color: $bg;
}

@include b('system-message') {
  margin: 15px 0;

  @include m('block-error') {
    @include makeBlock($color-error-text, $color-error-bg, $color-error-border);
  }

  @include m('block-info') {
    @include makeBlock($color-info-text, $color-info-bg, $color-info-border);
  }

  @include m('inline-error') {
    @include makeInlineBlock($color-error-text, $color-error-bg);
  }

  @include m('inline-info') {
    @include makeInlineBlock($color-info-text, $color-info-bg);
  }
}