@import '../../../styles/base/_bem.scss';
@import '../../../styles/base/_colors.scss';
@import '../../../styles/base/_types.scss';

@include b('table') {
  display: table;
  width: 100%;
  padding: 2px;

  @include e('row') {
    display: flex;
    flex-direction: row;
    margin-bottom: 6px;
    width: 100%;

    background: #f7f7f7;
    padding: 5px 0;
    border-radius: 4px;
    box-shadow: 0 1px 4px #ccc;

    &:hover {
      background-color: #f0f0f0;
    }

    @include m('header') {
      font-weight: bold;
      &:hover {
        background-color: #f7f7f7;
      }
    }

    @include m('nomargin') {
      margin: 0;
    }
  }

  @include e('col') {
    align-self: center;
    padding: 5px;
    width: auto;
    min-width: 110px;

    &:first-of-type {
      padding-left: 15px;
    }

    &:last-of-type {
      padding-right: 15px;
    }

    @include media-breakpoint-down(sm) {
      padding: 15px 5px;
    }

    @include m('hidden-sm') {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    @include m('top-sm') {
      @include media-breakpoint-down(sm) {
        align-self: flex-start;
      }
    }

    @include m('top') {
      align-self: flex-start;
    }

    @include m('icon') {
      width: 64px;
      min-width: 84px;

      span::before {
        font-size: 56px;
        color: #8fc0ba;
      }

      @include media-breakpoint-down(sm) {
        width: 48px;
        min-width: 68px;
        
        span::before {
          font-size: 40px;
        }
      }
    }

    @include m('small-icon') {
      width: 50px;
      min-width: 70px;

      span::before {
        font-size: 32px;
        color: #8fc0ba;
      }

      @include media-breakpoint-down(sm) {
        width: 32px;
        min-width: 52px;
        
        span::before {
          font-size: 24px;
        }
      }
    }

    @include m('long-text') {
      flex-grow: 1;
      width: auto;
      min-width: 200px;
    }

    @include m('short-text') {
      width: 130px;
      min-width: 130px;
    }

    @include m('mid-text') {
      width: 200px;
      min-width: 200px;
    }

    @include m('status') {
      text-align: right;
      width: 120px;
      min-width: 100px;
    }

    @include m('datetime') {
      text-align: left;
      width: 120px;
      min-width: 120px;
    }

    @include m('number') {
      text-align: right;
      width: 100px;
      min-width: 100px;
    }

    @include m('actions') {
      text-align: right;
      width: 350px;
      min-width: 350px;
    }

    @include m('small-actions') {
      text-align: right;
      width: 120px;
      min-width: 120px;
    }
  }

  @include e('row-default') {
    position: relative;
    top: -5px;
    opacity: 0;
  }

  @include e('row-enter') {
    position: relative;
    top: 0;
    opacity: 1;
    transition: all ease-in-out 0.2s;
  }

  @include e('row-leave') {
    position: relative;
    top: -10px;
    opacity: 0;
    transition: all ease-in-out 0.2s;
  }

  @include e('row-active') {
    position: relative;
    top: 0;
    opacity: 1;
  }
}