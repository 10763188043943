@import '../../../styles/base/_bem.scss';
@import '../../../styles/base/_colors.scss';
@import '../../../styles/base/_types.scss';

@include b('icon') {
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;

  @include e('image') {
    width: auto;
    height: 24px;
    display: block;
  }

  @include m('no-margin') {
    margin-right: 0;
  }
}