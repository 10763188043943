@import '../../../styles/base/_bem.scss';
@import '../../../styles/base/_colors.scss';
@import '../../../styles/base/_types.scss';
@import '../../../styles/base/_grid.scss';
@import '../../../styles/base/_clearfix.scss';

@include b('footer') {
  @include clearfix();

  background-color: $color-header-bg;
  color: $color-header-text;

  @include e('container') {
    @include make-container();
    @include make-container-max-widths();

    padding: 30px 0;

    @include media-breakpoint-down(sm) {
      padding: 30px 20px;
    }
  }
}  


