@import '../../styles/base/_bem.scss';
@import '../../styles/base/_colors.scss';
@import '../../styles/base/_types.scss';
@import '../../styles/base/_grid.scss';
@import '../../styles/base/_clearfix.scss';

@include b('page-reset-password') {
  @include clearfix();

  @include e('container') {
    @include makeRoundedBlock();

    width: 50%;
    margin: 0 auto;
    margin-top: 15%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  @include e('logo-container') {
    text-align: center;
    margin-bottom: 30px;
  }

  @include e('account-links') {
    margin-top: 30px;
    text-align: center;
  }
}  


