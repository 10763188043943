@import '../../styles/base/_bem.scss';
@import '../../styles/base/_colors.scss';
@import '../../styles/base/_types.scss';
@import '../../styles/base/_grid.scss';
@import '../../styles/base/_clearfix.scss';

@include b('form-edit-page') {
  @include clearfix();

  @include e('description') {
    margin-bottom: 20px;
  }

  @include e('form-endpoint') {
    @include makeFormContainer();
    margin-bottom: 20px;
  }

  @include e('form-container') {
    @include makeFormContainer();
  }

  @include e('row-container') {
    display: table;
    width: 100%;
    margin-bottom: 20px;

    @include m('header') {
      font-weight: bold;
    }
  }

  @include e('entry-point') {
    font-family: 'Courier New', Courier, monospace;
    font-size: 1rem;
  }
}
