﻿@import './_colors.scss';
@import './_breakpoints.scss';

$tabItemHeader: 24px;


@mixin makeHeader() {
  font-size: $tabItemHeader;
  font-family: Georgia, 'Times New Roman', Times, serif;
  line-height: normal;
  margin-bottom: 10px;
}

@mixin makeDescription() {
  margin-bottom: 10px;
}


@mixin makeItemBorder() {
  border: solid 1px $color-item-border;
}

@mixin makeAHint() {
  color: $color-text-muted;
  font-size: 0.85rem;
}

@mixin makeTextEllipsis($width) {
  width: $width;
  max-width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin fullWidthMinus($width) {
  width: calc(100% - #{$width});
}

@mixin makeRoundedBlock() {
  border: 1px solid #d5e5e1;
  border-radius: 15px;
  background-color: #d5e5e1;
  padding: 20px;
  margin-bottom: 30px;
}

@mixin makePageFormContainer () {
  @include makeRoundedBlock();

  width: 50%;
  margin: 0 auto;
  margin-top: 15%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

@mixin makeFormContainer () {
  margin-bottom: 40px;
  background-color: #d5e5e1;
  padding: 20px;
  border-radius: 6px;

  box-shadow: 0 1px 7px #ccc;
}
