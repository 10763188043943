@import '../../../styles/base/_bem.scss';
@import '../../../styles/base/_colors.scss';
@import '../../../styles/base/_types.scss';
@import '../../../styles/base/_form.scss';

@include b('toolbar') {
  // border: 1px solid #abe1d9;
  // border-radius: 6px;
  // background-color: #abe1d9;
  // box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
  padding: 10px 0;
  margin-bottom: 10px;

  @include e('item') {
    display: inline-block;
    margin-right: 10px;
    max-width: 250px;

    @include m('search') {
      width: 250px;
    }
  }

  input.toolbar {
    @include applyInputStdProps();
  }
}