@import './base/_colors.scss';

* {
  box-sizing: border-box;
}

html {
  min-height: 100%;
  width: 100%;
  background-color: #eaf2f0;
  color: #141f1c;
}

body {
  min-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Arial, sans-serif;
  font-size: 16px;
  line-height: 1.42857143;
  background-attachment: fixed;
  background-size: contain;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0 0 15px 0;
    color: #141f1c;
}

h1, h2, h3 {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 600;
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 28px;
}

h3 {
    font-weight: 500;
}

p {
    margin: 0 0 10px 0;
}

img.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

img.img-30-percent {
  max-width: 30%;
}

img.img-60-percent {
  max-width: 60%;
}

img.img-80-percent {
  max-width: 80%;
}

span.label {
  display: inline-block;
  padding: 0 8px 3px;
  border-radius: 10px;
  vertical-align: middle;
  font-size: 0.80em;
}

.logo {
  display: inline-block;
  font-size: 20px;
  font-family: 'Verdana', Arial, Helvetica, sans-serif;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #133631;
  padding: 7px 0 10px;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.24);
}

.text-center {
  text-align: center;
}

#root {
  min-height: 100%;
}

div.responsive {
  overflow-x: auto;
  overflow-y: unset;
  width: 100%;
  margin-bottom: 30px;
}

.space-top-delimeter {
  margin-top: 15px;
}

.text-muted {
  color: $color-text-muted;
}

.url {
  font-family: 'Courier New', Courier, monospace;
  font-size: 1rem;
  word-break: break-all;
}

.success {
  color: $color-success-text;
}

.danger {
  color: $color-error-text;
}

@import 'base/_buttons.scss';
@import 'base/_form.scss';
