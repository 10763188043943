@import '../../../styles/base/_bem.scss';
@import '../../../styles/base/_colors.scss';
@import '../../../styles/base/_types.scss';
@import '../../../styles/base/_grid.scss';
@import '../../../styles/base/_clearfix.scss';

@include b('nav') {
  color: #fff;

  @include e('items') {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      padding: 0;
    }

    @include media-breakpoint-up(md) {
      display: inline-block;
    }

    @include media-breakpoint-down(sm) {
      display: none;

      @include m('toggled') {
        display: block;
        position: fixed;
        left: 0;
        right: 0;
        top: 50px;
        bottom: 0;
        z-index: 200;
        background-color: $color-header-bg;
        
        li {
          display: block;
          padding: 0;
        }
      }
    }
  }

  @include e('nav-link') {
    display: block;
    padding: 14px 10px;
    text-decoration: none;
    color: #f0f0f0;
    transition: all ease-in-out 0.1s;

    &:active,
    &:hover,
    &:focus {
      text-decoration: none;
      color: $color-header-text-active;
      background-color: $color-header-bg-active;
    }

    @include m('active') {
      color: $color-header-text-active;
      background-color: $color-header-bg-active;
    }
  }

  @include e('toggle') {
    color: $color-header-text !important;

    @include media-breakpoint-up(sm) {
      display: none;
    }
    @include media-breakpoint-down(sm) {
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 0;
      bottom: 0;
    }
  }

  @include e('mobile') {
    display: none !important;
    @include media-breakpoint-down(sm) {
      display: block !important;
    }
  }
}  
