﻿@import '_colors.scss';

button.btn {
  color: $color-btn-text;
  border: solid 1px $color-btn-border;
  background-color: $color-btn-bg;
  padding: 4px 20px 6px;
  margin: 2px 5px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: $color-btn-text-hover;
    border-color: $color-btn-border-hover;
    background-color: $color-btn-bg-hover;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  &:disabled {
    color: $color-btn-text-disabled;
    background-color: $color-btn-bg-disabled;
    border-color: $color-btn-border-disabled;
    cursor: initial;

    &:hover,
    &:focus {
      background-color: $color-btn-bg-disabled;
      transform: none;
    }
  }

  &.action {
    padding: 7px 9px;
    border-radius: 100%;

    &.checked {
      background-color: $color-btn-action-checked-bg;
      color: $color-white;

      &:hover,
      &:focus {
        background-color: $color-btn-action-checked-bg-hover;
        transform: none;
        color: $color-white;
      }

      &:disabled {
        background-color: $color-btn-action-checked-bg-disabled;
        transform: none;
        color: $color-text-muted;
      }
    }
  }

  &.small {
    padding: 4px 17px;
    font-size: 0.9em;
  }

  &.smaller {
    padding: 3px 14px;
    font-size: 0.8em;
  }

  &.smallest {
    padding: 2px 11px;
    font-size: 0.7em;
  }

  &.big {
    padding: 7px 20px;
    font-size: 1.05em;
  }

  &.bigger {
    padding: 10px 20px;
    font-size: 1.10em;
  }

  &.biggest {
    padding: 13px 20px;
    font-size: 1.15em;
  }

  &.block {
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  &.primary {
    color: $color-btn-primary-text;
    background-color: $color-btn-primary-bg;
    border-color: $color-btn-primary-border;

    &:hover,
    &:focus {
      color: $color-btn-primary-text-hover;
      border-color: $color-btn-primary-border-hover;
      background-color: $color-btn-primary-bg-hover;
    }

    &:disabled {
      color: $color-btn-primary-text-disabled;
      background-color: $color-btn-primary-bg-disabled;
      border-color: $color-btn-primary-border-disabled;
    }
  }

  &.secondary {
    color: $color-btn-secondary-text;
    background-color: $color-btn-secondary-bg;
    border-color: $color-btn-secondary-border;

    &:hover,
    &:focus {
      color: $color-btn-secondary-text-hover;
      border-color: $color-btn-secondary-border-hover;
      background-color: $color-btn-secondary-bg-hover;
    }

    &:disabled {
      color: $color-btn-secondary-text-disabled;
      background-color: $color-btn-secondary-bg-disabled;
      border-color: $color-btn-secondary-border-disabled;
    }
  }

  &.link {
    color: $color-btn-link-text;
    background-color: $color-btn-link-bg-disabled;
    background-color: $color-btn-link-bg;
    border: none;

    &:hover,
    &:focus {
      color: $color-btn-link-text-hover;
      background-color: $color-btn-link-bg-disabled;
      background-color: $color-btn-link-bg-hover;
    }

    &:disabled {
      color: $color-btn-link-text-disabled;
      background-color: $color-btn-link-bg-disabled;
      border-color: $color-btn-link-border-disabled;
    }
  }
}

