@import '../../styles/base/_bem.scss';
@import '../../styles/base/_types.scss';
@import '../../styles/base/_grid.scss';
@import '../../styles/base/_clearfix.scss';

@include b('page-not-found') {
  @include clearfix();

  @include e('container') {
    @include makePageFormContainer();

    text-align: center;
  }

  @include e('logo-container') {
    text-align: center;
    margin-bottom: 30px;
  }
}
